<template>
    <div class="tembo-casino">
        <div class="modal fade" id="exampleModal-lode" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"
                data-bs-backdrop="static" data-bs-keyboard="false">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">-</h5>
                        <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close" @click="$emit('modelShown')">×</button>
                    </div>
                    <div class="modal-body">
                        <div class="live-card-game">
                            <img class="on-lode-img" :src="siteSettings?.aws_url + siteSettings?.storage_path.poster_path + sitePosters?.image" :alt="sitePosters?.image">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Modal } from 'bootstrap';
export default {
    name: 'OnLoadModal',
    data() {
        return {
            bodyloadmodal: null,
            siteSettings: this.$store.getters.siteSettings
        }
    },
    mounted() {
        this.bodyloadmodal = new Modal("#exampleModal-lode");
        this.showOnLoadModal();
    },
    computed: {
        isLogin() {
            return this.$store.getters.isAuthenticated
        },
        sitePosters() {
            if(this.siteSettings && this.siteSettings.poster && this.siteSettings.poster.length) {
                return this.siteSettings.poster.filter(s => s.poster_type == 1)[0];
            } else {
                return [];
            }
        }
    },
    methods: {
        showOnLoadModal() {
            if (this.isLogin && this.sitePosters?.length) {
                this.bodyloadmodal.show();
            }
        }
    }
}
</script>

<style></style>