<template>
    <header class="heading-section" loading="lazy">

        <div class="flex-area">
            <div class="logo header-logo">
                <router-link :to="{ name: 'Sports' }" class="logo">
                    <!-- <i class="fa-sharp fa-solid fa-house-chimney mobile-logo"></i> -->
                    <i><img src="@/assets/images/header-home-icon.svg"></i>
                    <img :src="siteSettings?.aws_url + siteSettings?.storage_path.domain_image + siteSettings?.logo"
                        alt="logo">
                </router-link>
            </div>
            <div class="nav-right-side" v-if="$store.getters.isAuthenticated">
                <ul class="cum-list">
                    <li class="search">
                        <input type="search" name="search" placeholder="All Events" class="search-input-show" /> <span
                            class="open-search"> <i class="fas fa-search-plus"></i> </span>
                    </li>
                    <li class="rules">
                        <router-link :to="{ name: 'Rules' }"> <b> {{translatedLangData('rules', 'Rules')}}</b> </router-link>
                    </li>
                    <li class="balance">
                        <div class="amount">
                            <span>{{translatedLangData('balance', 'Balance')}}:</span> <b><span>{{ parseInt(stateUser?.balance).toFixed(2) }}</span></b>
                        </div>
                    </li>
                    <li class="account">
                        <div class="mobile-amount" id="balancecheck" v-if="balanceExpoDisplay.includes('balance')">
                            <button class="bank-amount">                                
                               <span>{{translatedLangData('balance', 'Balance')}}:</span>&nbsp;<b>{{ parseInt(stateUser?.balance).toFixed(2) }}</b>
                            </button>
                        </div>
                        <div class="d-flex justify-end">
                            <div class="exposure" id="divcheck" v-if="balanceExpoDisplay.includes('exposure')">
                            <a href="Javascript:void(0);" data-bs-toggle="modal" data-bs-target="#exampleModal"
                                @click="callForMyMarket()">
                                <span class="">{{translatedLangData('exposure', 'Exp')}}:</span> <span class="exp-amount">{{
                                    parseInt((-1) * (stateUser?.expl)).toFixed(2) }}</span>
                            </a>
                            </div>
                            <div class="dropdown dropdown-account">
                                <button role="button" class="btn dropdown-toggle" type="button" id="dropdownMenuButton1"
                                    data-bs-toggle="dropdown" aria-controls="acc-menu-btn123" >
                                    <span>{{ stateUser?.userid }}</span>
                                    <img src="@/assets/images/down.webp" alt="">
                                </button>

                                <ul class="dropdown-menu headAcc" >
                                    <li class="">
                                        <div class="setting-user-checkbox">
                                            <div class="user-details-r">
                                                <label class="form-check-label" for="flexSwitchCheckDefault">{{translatedLangData('one-click-bet', 'One Click Bet')}}</label>
                                                <div class="form-check form-switch">
                                                    <input class="form-check-input" id="flexSwitchCheckDefault" type="checkbox" v-model="clickBetCheck"
                                                        @change="betCheckChangeFunc()">
                                                </div>
                                                <div v-if="clickBetCheck && getClickBetSelectedValue()"
                                                    @click="$refs.open_click_bet_value.click()">
                                                    <label>{{ getClickBetSelectedValue() }}</label>
                                                </div>

                                            </div>
                                            <a style="display: none;" ref="open_click_bet_value" data-bs-toggle="modal"
                                                data-bs-target="#clickBetValues"></a>

                                        </div>
                                    </li>                                
                                <div class="welcome-bonus-bx">
                                    <!-- <li class="bonnus">
                                        <a href="#weeklyrewardModal" data-bs-toggle="modal" @click="getRules()">
                                            <span>{{translatedLangData('weekly-reward', 'Weekly Reward')}}</span>
                                            <span class="float-end"><img class="info-icon-bonus" src="@/assets/images/info-icon.webp" /></span>
                                        </a>
                                    </li> -->
                                    <li class="earn-locked-bonus-bx">
                                        <div class="locked-bonus-bx earn-bonus-bx" v-if="lockedEarnedData">
                                            <a href="Javascript:void(0);">
                                                    <span class="earn-bonus-text">{{translatedLangData('earn-bonus', 'Earn Bonus')}}</span>
                                                    <span class="float-end"> {{ lockedEarnedData.earnBonus }}</span>
                                            </a>
                                            <span class="locked-bonus-info" v-if="siteSettings.business_type == 2">
                                                <a href="#informationModal" data-bs-toggle="modal" @click="getRules()">                                      
                                                    <span class="float-end"><img class="info-icon-bonus" src="@/assets/images/info-icon.webp" /></span>
                                                </a>
                                            </span>
                                        </div>
                                        <div class="locked-bonus-bx" v-if="lockedEarnedData">
                                            <a href="Javascript:void(0);" @click="openBonusLogs()">
                                                <span class="locked-bonus-btn">{{translatedLangData('locked-bonus', 'Locked Bonus')}}</span>
                                                <span class="float-end"> {{ lockedEarnedData.lockedBonus }}</span>
                                            </a>
                                            <span class="locked-bonus-info" v-if="siteSettings.business_type == 2">
                                            <a href="Javascript:void(0);" @click="openBonusLogs()">                                      
                                                <span class="float-end"><img class="info-icon-bonus" src="@/assets/images/info-icon.webp" /></span>
                                            </a>
                                            </span>
                                        </div>
                                    </li>
                                    <!-- <li class="bonnus" v-if="siteSettings.business_type == 2">
                                        <a href="#informationModal" data-bs-toggle="modal" @click="getRules()">
                                            <span>Bonus Information</span>
                                            <span class="float-end"><img class="info-icon-bonus" src="@/assets/images/info-icon.webp" /></span>
                                        </a>
                                    </li> -->
                                    <li class="bonnus pe-0 welcome-bonus-bg" v-if="lockedEarnedData">
                                        <a href="Javascript:void(0);" class="p-0 d-block">
                                            <div class="bonus-sec">
                                                <div class="credits-chackn-box">
                                                    <div class="menu-details-heading wel-bonus">
                                                        <h4> {{translatedLangData('welcome-bonus', 'Welcome Bonus')}} </h4>
                                                    </div>
                                                    <div class="menu-heading-con welcome-bonus">
                                                        <strong> {{ lockedEarnedData.welcomeBonus }}</strong>
                                                    </div>
                                                    <!-- <span href="#informationModal" data-bs-toggle="modal" @click="getRules()" class="float-end"><img class="info-icon-bonus" src="@/assets/images/info-icon.webp" /></span> -->
                                                </div>
                                                <div class="credits-chackn-box">
                                                    <div class="menu-details-heading wel-bonus">
                                                        <h4>{{translatedLangData('play-with-bonus', 'Play with Bonus')}}</h4>
                                                    </div>
                                                    <div class="menu-heading-con">
                                                        <div class="form-check form-switch m-0 p-0">
                                                            <input class="form-check-input" type="checkbox"
                                                                id="settingCheckDefaults" v-model="getWalletType" @change="updateWalletType" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </a>
                                    </li>
                                    <li class="refer-earn-btn-sidebar" >
                                        <router-link :to="{name: 'refer-earn'}">
                                            <!-- <img src="@/assets/images/giftbox.webp" alt="" /> -->
                                            <span>{{translatedLangData('refer-and-earn', 'Refer & Earn')}}</span>
                                        </router-link>
                                    </li>
                                </div>

                                    <li>
                                        <a href="#select-language-btn" data-bs-toggle="modal">
                                            <!-- <img src="@/assets/images/passwordHistory.webp" alt="" /> -->
                                        <span>{{translatedLangData('select-language', 'Select Language')}}</span>
                                        </a>
                                    </li>
                                    <li v-if="siteSettings?.business_type == 2">
                                        <router-link :to="{ name: 'wallet-gateway' }">
                                            <!-- <img src="@/assets/images/wallet (1).png" alt="" /> -->
                                            <span>{{translatedLangData('wallet', 'Wallet')}}</span>
                                        </router-link>
                                    </li>
                                    <li>
                                        <router-link :to="{ name: 'AccountStatement' }">
                                            <!-- <img src="@/assets/images/account.webp" alt="" /> -->
                                        <span>{{translatedLangData('account-statement', 'Account Statement')}}</span>
                                        </router-link>
                                    </li>
                                    

                                    <li>
                                        <router-link :to="{ name: 'profit-and-loss' }">
                                            <!-- <img src="@/assets/images/Profit.webp" alt="" /> -->
                                        <span>{{translatedLangData('profit-loss-report', 'Profit Loss Report')}}</span>
                                        </router-link>
                                    </li>

                                    <li>
                                        <router-link :to="{ name: 'bet-history' }">
                                            <!-- <img src="@/assets/images/betHistory.webp" alt="" /> -->
                                        <span>{{translatedLangData('bet-history', 'Bet History')}}</span>
                                        </router-link>
                                    </li>

                                    <li>
                                        <router-link :to="{ name: 'unsettled-bet' }">
                                            <!-- <img src="@/assets/images/betHistory.webp" alt="" /> -->
                                        <span>{{translatedLangData('unsettled-bets', 'Unsettled Bets')}}</span>
                                        </router-link>
                                    </li>

                                    <li>
                                        <!-- <router-link :to="{ name: 'set-button-value' }"> -->
                                        <a data-bs-toggle="modal" data-bs-target="#buttonvalue">
                                            <!-- <img src="@/assets/images/betHistory.webp" alt="" /> -->
                                            <span>{{translatedLangData('set-button-values', 'Set Button Values')}}</span>
                                        </a>
                                    </li>
                                    <li>
                                        <!-- <router-link :to="{ name: 'Rules' }"> 
                                            <span>{{translatedLangData('rules', 'Rules')}}</span> 
                                        </router-link> -->
                                        <a data-bs-toggle="modal" data-bs-target="#rulemodal">
                                            <span>{{translatedLangData('rules', 'Rules')}}</span> 
                                        </a>
                                            
                                    </li>

                                    <!-- <li>
                                            <router-link :to="{ name: 'Security-and-auth' }">
                                                <img src="@/assets/images/betHistory.webp" alt="" />
                                                <span>Security Auth Verification</span>
                                            </router-link>
                                        </li> -->

                                    <li>
                                        <router-link :to="{ name: 'change-password' }" >
                                            <!-- <img src="@/assets/images/passwordHistory.webp" alt="" /> -->
                                            <span>{{translatedLangData('change-password', 'Change Password')}}</span>
                                        </router-link>
                                    </li>

                                    <li onclick="event.stopPropagation();">
                                        <div class="dropdown-item-mobile-nav" >
                                            <div class="custom-control custom-checkbox">
                                                <input type="checkbox"
                                                    id="customCheck" class="custom-control-input" v-model="balanceExpoDisplay"
                                                    :value="'balance'" @change="updateBalExpDisplay()" />
                                                <label for="customCheck" class="custom-control-label">{{translatedLangData('balance', 'Balance')}}</label></div>
                                            </div>
                                    </li>
                                    <li onclick="event.stopPropagation();">
                                        <div class="dropdown-item-mobile-nav" >
                                            <div class="custom-control custom-checkbox">
                                                <input type="checkbox" id="customCheck-one" class="custom-control-input"
                                                    v-model="balanceExpoDisplay" :value="'exposure'"
                                                    @change="updateBalExpDisplay()" /> 
                                                    <label for="customCheck-one" class="custom-control-label">{{translatedLangData('exposure', 'Exposure')}}</label>
                                            </div>
                                        </div>
                                    </li>

                                    <li class="log-out">
                                        <a href="Javascript:void(0);" @click="logoutBtnClick()"><span>{{translatedLangData('logout', 'Logout')}}</span></a>
                                    </li>

                                </ul>

                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="login-and-register-btn" v-else>
                <ul class="cmn-ul-list">
                    <li>
                        <router-link :to="{name : 'Login'}" class="btn cmn-bdr-btn" href="Javascript:void(0);">{{translatedLangData('log-in', 'Login')}}</router-link>
                    </li>
                    <li>
                        <router-link :to="{name : 'SignUp'}" class="btn thm-but" href="Javascript:void(0);">{{translatedLangData('register', 'Register')}}</router-link>
                    </li>
                </ul>
            </div>
        </div>

        <div class="marque">
            <div class="search-box-container">
                <div class="search-box-mobile">
                    <input type="search" ref="searchInput" class="input-search" @keyup="searchIcon"
                        v-model="searchText" @blur="clearSearch()" :placeholder="translatedLangData('search-here', 'Search Here')" />
                    <button class="btn-search" @click="clickOnSearch()"><img src="@/assets/images/header-secrch-icon.svg"></button>
                </div>
                <div class="px-2">
                    <marquee :style="{ color: siteSettings?.announcement?.color_code }" scrollamount="3" v-if="siteSettings && siteSettings.announcement && siteSettings.announcement.msg">{{siteSettings.announcement.msg}}</marquee>
                </div>
                <div class="deposit-and-withdrawal-cmn-btn" v-if="siteSettings?.business_type == 2 && $store.getters.isAuthenticated">
                    <router-link :to="{ name: 'wallet-gateway' }" class="deposit-btn12 bg-success">{{translatedLangData('deposit', 'Deposit')}}</router-link>
                    <router-link :to="{ name: 'wallet-gateway' }" class="withdrawal-btn12 bg-danger">{{translatedLangData('withdrawal', 'Withdrawal')}}</router-link>
                </div>
                <!-- <div class="suggest-list" id="btn1"
                    v-if="searchResult && searchResult.matches && searchResult.matches.length > 0">
                    <ul>

                        <li v-for="(search, sIndex) in searchResult.matches" :key="sIndex">
                            <a href="Javascript:void(0);" class="search-data" @click="gotoEvent(search)">
                                <div>
                                    <div class="search-game-name">
                                        <b class="text-capitalize">{{ search.sport_name == 'soccer' ? 'Football' : search.sport_name }}</b>
                                    </div>
                                    <div class="game-date text-right">
                                        {{ formatTime(search.event_date) }}
                                    </div>
                                    <div class="game-teams">{{ search.event_name }}</div>
                                </div>
                            </a>
                        </li>

                    </ul>
                </div> -->

                <div class="suggest-list" id="btn1"
                    v-if="searchText && searchResult && searchResult.matches && searchResult.matches.length > 0">
                    <ul>

                        <li v-for="(search, sIndex) in searchResult.matches" :key="sIndex">
                            <a href="Javascript:void(0);" class="search-data" @click="gotoEvent(search)">
                                <div class="search-title-header">
                                    {{ search.sport_name == 'soccer' ? 'Football' : search.sport_name }}
                                </div>
                                <div class="game-teams-title">
                                    <h5>{{ search.event_name }}</h5>
                                    <span>{{ formatTime(search.event_date) }}</span>
                                </div> 
                            </a>
                        </li>

                    </ul>
                </div>

            </div>
            
        </div>

    </header>
    <!-- header-flag -->
    <!-- <div class="header-b-menu" v-if="checkForShow('headerFlag')">
        <div :class="index % 2 == 0  ? 'ipl' : 'election'" v-for="(item, index) in sportsList" :key="index" @click="goToSportEvent(item)">
            <a href="Javascript:void(0);" class="text-link router-link-exact-active router-link-active">{{ item.name }}</a>
        </div>
    </div> -->
    <div class="latest-event">
        <div class="latest-event-item" v-for="(recMatch, rIndex) in getRecommendedMatch" :key="rIndex">
            <a @click="this.$router.push({name: 'SportsEventDetail', params: { event_id: recMatch.event_id } })" class="new-launch-text blink_me">
                <img alt="" src="@/assets/images/cricket-img.png" v-if="recMatch.sport_id == 4">
                <img alt="" src="@/assets/images/tennis-img.png" v-if="recMatch.sport_id == 2">
                <img alt="" src="@/assets/images/1.webp" v-if="recMatch.sport_id == 1">
                <span>{{ recMatch.event_name }}</span>
            </a>
        </div>        
    </div>


    <div class="mobile-view-sec" loading="lazy" v-if="checkForShow('sportsEvent')">
        <div class="game-nav-bar">
            <ul class="nav">
                <!-- <li class="nav-item" :class="{ active: $route.name == 'Home' }">
                    <router-link to="/" class="nav-link">
                        {{translatedLangData('inplay-footer-label', 'In-play')}}
                    </router-link>
                </li> -->
                <li class="nav-item" :class="{ active: $route.name == 'Sports' }">
                    <router-link :to="{ name: 'Sports' }" class="nav-link">
                        {{translatedLangData('sports', 'Sports')}}
                    </router-link>
                </li>
                <li class="nav-item" :class="{ active: $route.name == 'sports-book' }">
                    <a href="Javascript:void(0);" class="nav-link" @click="gotoSportsbook()">
                        {{translatedLangData('sportsbook', 'Sports Book')}}
                    </a>
                </li>
                <li class="nav-item" :class="{ active: $route.name == 'our-casino' }">
                    <router-link :to="{ name: 'our-casino' }" class="nav-link">
                        {{translatedLangData('our-casino', 'Our Casino')}}
                    </router-link>
                </li>
                <li class="nav-item" :class="{ active: $route.name == 'live-casino' }">
                    <router-link :to="{ name: 'live-casino' }" class="nav-link">
                        {{translatedLangData('live-casino', 'Live Casino')}}
                    </router-link>
                </li>
                <li class="nav-item" :class="{ active: $route.name == 'slots' }">
                    <router-link :to="{ name: 'slots' }" class="nav-link">
                        {{translatedLangData('slots', 'Slots')}}
                    </router-link>
                </li>
                <li class="nav-item" :class="{ active: $route.name == 'evolution' }">
                    <router-link :to="{ name: 'evolution' }" class="nav-link">
                        {{translatedLangData('evolution', 'Evolution')}}
                    </router-link>
                </li>
                <li class="nav-item" :class="{ active: $route.name == 'fantasy' }">
                    <router-link :to="{ name: 'fantasy' }" class="nav-link">
                        {{translatedLangData('fantasy', 'Fantasy')}}
                    </router-link>
                </li>
                <li class="nav-item" :class="{ active: $route.name == 'Matka' || $route.path.includes('matka')}">
                    <router-link to="/matka" class="nav-link">
                        {{translatedLangData('matka', 'Matka')}}
                    </router-link>
                </li>

                <li class="nav-item" :class="{ active: $route.name == 'cricketFight'  || $route.path.includes('cricket-fight') }">
                    <router-link to="/cricket-fight" class="nav-link bd-right">
                        {{translatedLangData('cricket-fight', 'Cricket-Fight')}}
                    </router-link>
                </li>

            </ul>
        </div>
    </div>

    <Rule />
    <LockBonus v-if="showBonusLogs" @success-modal="showSuccessModalFunc" @error-modal="showErrorModalFunc" @closeBonusLogs="closeBonusLogs()" />
    <BonusInformation :siteRuleData="siteRuleData"/>
    <LanguageModal @error-modal="showErrorModalFunc" @success-modal="showSuccessModalFunc"></LanguageModal>
    <MyMarket ref="myMarket" />
    <setValue ref="setValue" @error-modal="showErrorModalFunc" @success-modal="showSuccessModalFunc" />
    <OnLoadModal v-if="showPromoModal" @modelShown="showPromoModal = false"></OnLoadModal>
    <ClickBetModal @success-modal="showSuccessModalFunc" @error-modal="showErrorModalFunc" @cancelOneClick = "clickBetCheck = false" />
</template>

<script >
// import SideMenu from "./SideMenu.vue";
import LockBonus from '../modal/LockBonus.vue';
import BonusInformation from '../modal/BonusInformation.vue';
import MyMarket from '../modal/MyMarket.vue';
import setValue from '@/modules/account/views/setValue.vue';
import Rule from '../rules/Rules.vue';
import OnLoadModal from "@/shared/components/modal/OnLoadModal.vue";
import ClickBetModal from '@/modules/account/components/ClickBetModal.vue';
import LanguageModal from './LanguageModal.vue';

import { mapGetters } from 'vuex';
import api from '../../services/api';
import * as apiName from '../../services/urls';
import { AUTH_TOKEN } from "@/shared/constants/cookies-const";
import moment from 'moment';
import { kFormatter } from '@/shared/utils/formatter';
import captchaApi from '@/modules/authorization/services/captcha-api';

export default {
    name: "Header",
    inject: ['translatedLangData'],
    components: {
        // SideMenu,
        LockBonus,
        BonusInformation,
        MyMarket,
        setValue,
        Rule,
        OnLoadModal,
        ClickBetModal,
        LanguageModal
    },
    data() {
        return {
            showPromoModal: true,
            searchResult: null,
            searchText: "",
            clickBetCheck: this.$store?.getters?.isClickBetOn ? this.$store?.getters?.isClickBetOn : false,
            balanceExpoDisplay: [],
            sportsList: [],
            lockedEarnedData: null,
            showBonusLogs: false,
            getWalletType: false,
            siteRuleData: null
        };
    },
    mounted() {
        this.sportsList = JSON.parse(localStorage.getItem('sports_List'))?.custom;
        // if (this.getClickBetValue()) {
        //     this.clickBetCheck = true
        // }
        this.balanceExpoDisplay = this.getBalExpDisplay
        if(this.checkIsLogin && this.siteSettings.business_type == 2) {
            this.getLockedEarnedBonus();
            this.getWalletType = this.stateUser?.activeWallet == 'WB';
        } 
    },
    computed: {
        ...mapGetters([
            'siteSettings', 'stateUser', 'getBalExpDisplay', 'getRecommendedMatch'
        ]),
        checkIsLogin() {
            return this.$store?.getters?.isAuthenticated;
        },
    },
    methods: {
        debounce(callback, timer=1000) {
            const context = this;
            clearTimeout(this.timeoutId);
            this.timeoutId = setTimeout(() => {
                callback.apply(context);
            }, timer);
        },
        searchIcon(){
            this.debounce(function(){
                  this.searchCasinoMatches()
            }, 600);
        },
        goToSportEvent(item) {
            if(this.$route.name != 'Sports') {
                this.$router.push({name: 'Sports', params: {type: item.slug ,id : item.id, head_sport: 1}});
            } else {
                const sportComponent = this.$router.getRoutes().find(route => route.name === 'Sports');
                if (sportComponent) {
                    const instance = sportComponent.instances.default;
                    if (instance && typeof instance.getSportsList === 'function') {
                        instance.getSportsList(item);
                    }
                }
            }
        },
        checkForBackArrow() {
            let routeName = this.$route.name;
            let routeType = this.$route.params.type;
            switch (routeName) {
                //checking for show three dot menu
                case "Home":
                case "Inplay":
                    return true;
                case "games":
                    return routeType == "GAMES";
                default:
                    return false;
            }
        },
        checkForShow(event) {
            let routeName = this.$route.name;
            if(event == 'headerFlag'){
                let ShowRoutes = ['Sports','Home']
                return ShowRoutes.includes(routeName)
            }
            else{
                let noShowRoutes = ['SportsEventDetail','sports-book','AccountStatement','profit-and-loss','unsettled-bet','set-button-value','ProfitLossDetail','bet-history','change-password','wallet-gateway','casino-report-history']
                return !noShowRoutes.includes(routeName)
            }


        },
        callVoiceSearchFlag() {
            this.$emit("voice-search-flag");
        },
        getRules() {
            this.loading = true;
            if(this.siteRuleData) {
                return;
            }
            let headers = {
                'Authorization': `Bearer ${this.$cookies.get(AUTH_TOKEN)}`
            }
            api.get(apiName.GET_SITE_RULES, { headers }).then(response => {
                this.loading = false;
                this.$store.dispatch('setGlobalLoader', false);
                if (response) {
                    if (response.status == 200) {
                        if (response?.data?.status == 0) {
                            this.$emit('error-modal', response.data.debug[0]);
                        }
                        else {
                            let ruleResp = response.data.data;
                            this.siteRuleData = {}
                            ruleResp.forEach(item => {
                                this.siteRuleData[item.type] = item.content;
                            })
                        }
                    }
                }
            }).catch(error => {
                this.loading = false;
                if (error) {
                    this.$emit('error-modal', error[0]);
                }
            });
        },
        searchCasinoMatches() {
            try {
                this.searchResult = null;
                this.loading = true;
                let headers = {
                    'Authorization': `Bearer ${this.$cookies.get(AUTH_TOKEN)}`
                }
                let data = {
                    "search": this.searchText
                };
                api.post(apiName.SEARCH, data, { headers }).then(response => {
                    this.loading = false;
                    if (response && response.status && response.status == 200) {
                        let resData = response.data.data ?? null;
                        this.searchResult = resData;
                    } else {
                        // this.showErrorModalFunc(response.data.debug[0]);
                    }
                }).catch(error => {
                    this.loading = false;
                    if (error) {
                        console.log(error)
                        // this.showErrorModalFunc(error[0]);
                    }
                });
            } catch (error) {
                console.log(error)

            }
        },
        formatTime(date) {
            return moment(date).format('DD/MM/YYYY hh:mm:ss A');
        },
        gotoEvent(item) {
            this.$router.push({ name: 'SportsEventDetail', params: { event_id: item.event_id } });
            this.searchResult = null;
            this.searchText = ''
        },
        clickOnSearch() {
            this.$refs.searchInput.focus();
        },
        clearSearch() {
            setTimeout(() => {
                this.searchResult = null;
                this.searchText = ''
            }, 200)
        },
        betCheckChangeFunc() {
            if (this.clickBetCheck) {
                this.$refs.open_click_bet_value.click();
            }
            else {
                this.$store.dispatch('setIsClickBetOn', false);
                this.showSuccessModalFunc('One Click Bet is inactive.')
                // this.$store.dispatch('setClickBetValue', null);
            }
        },
        getClickBetSelectedValue() {
            let inputValues = this.getClickBetValue();
            if (inputValues)
                return inputValues.filter(item => item.isSelected)[0]?.value;
        },
        getKFormatter(num) {
            return kFormatter(num);
        },
        getClickBetValue() {
            return this.$store.getters.clickBetValue ? [...this.$store.getters.clickBetValue] : null;
        },
        callForMyMarket() {
            this.$refs.myMarket.getMyMarketsServiceCall('sport');
        },
        updateBalExpDisplay() {
            this.$store.dispatch('setBalExpDisplay', this.balanceExpoDisplay)
        },
        logoutBtnClick() {
            this.logoutServiceCall();
        },
        logoutServiceCall() {

            let headers = {
                'Authorization': `Bearer ${this.$cookies.get(AUTH_TOKEN)}`
            }
            api.get(apiName.LOGOUT_API, { headers }).then(response => {
                this.loading = false;
                if (response) {
                    if (response.status == 200) {
                        if (response?.data?.status == 0) {
                            this.$emit('error-modal', response.data.debug[0])
                        }
                        else {
                            this.$emit('success-modal', response?.data?.message);
                            // remove all cookie
                            this.$cookies.keys().forEach(cookie => this.$cookies.remove(cookie))
                            this.$store.dispatch('resetAllState');
                            this.$router.push({ name: 'Login' });
                        }
                    }
                }
            }).catch(error => {
                this.loading = false;
                if (error) {
                    this.$emit('error-modal', error[0]);
                }
            });
        },
        gotoSportsbook() {
            if(this.checkIsLogin) {
                this.$router.push({ name: 'sports-book' })
            } else {
                this.$emit('error-modal', this.translatedLangData('login-for-sportsbook','Please Login to Access Sportsbook!!'));
            }
        },
        getLockedEarnedBonus() {
            let authToken = this.$cookies.get(AUTH_TOKEN);
			let headers = {
				'Authorization': `Bearer ${authToken}`
			}
            api.get(apiName.GET_LOCKED_EARNED_BONUS, {headers}).then(response => {
                if (response) {
                    if (response.status == 200) {
                        if (response?.data?.status == 0) {
                            this.$emit('error-modal', response.data.debug[0]);
                        }
                        else {
                            this.lockedEarnedData = response.data.data;
                        }
                    }
                }
            }).catch(error => {
                this.$store.dispatch('setGlobalLoader', false);
                if (error) {
                    this.$emit('error-modal', error[0]);
                }
            });
        },
        openBonusLogs() {
            this.showBonusLogs = true;
        },
        closeBonusLogs() {
            setTimeout(() => {
                this.showBonusLogs = false;
            }, 400)
        },
        updateWalletType() {
            let authToken = this.$cookies.get(AUTH_TOKEN);
			let headers = {
				'Authorization': `Bearer ${authToken}`
			}
            api.post(apiName.UPDATE_WALLET_TYPE, {} , {headers}).then(response => {
                if (response) {
                    if (response.status == 200) {
                        if (response?.data?.status == 0) {
                            this.$emit('error-modal', response.data.debug[0]);
                        }
                        else {
                            let user = this.stateUser;
                            user.activeWallet = response.data.data.active_wallet;
                            this.$store.dispatch('setUser', user);
                            this.$emit('success-modal', 'Successfully changed');
                            setTimeout(() => {
                                this.$emit('updateAuthToken', true);
                            },2000)
                        }
                    }
                }
            }).catch(error => {
                this.$store.dispatch('setGlobalLoader', false);
                if (error) {
                    this.getWalletType = !this.getWalletType;
                    this.$emit('error-modal', error[0]);
                }
            });
        },
        showErrorModalFunc(message) {
            this.$emit('error-modal', message);
        },
        showSuccessModalFunc(message) {
            this.$emit('success-modal', message);
        },
    },
};
</script>

<style>

li.bonnus a {
    display: flex;
    justify-content: space-between;
}

.menu-details-heading.wel-bonus h4 {
    color: #000000;
}

.menu-heading-con.welcome-bonus strong {
    color: #000000 !important;
}

.credits-chackn-box {
    padding-right: 15px !important;
}
.info-icon-bonus {
    width: 15px;
}
</style>